@font-face {
    font-family: 'Avnir';
    font-weight: 900;
    src: local('Avnir'), url(./assets/fonts/AvenirLTStd-Black.otf) format('opentype');
  }

@font-face {
    font-family: 'Avnir';
    font-weight: 500;
    src: local('Avnir'), url(./assets/fonts/AvenirLTStd-Book.otf) format('opentype');
}

@font-face {
    font-family: 'Avnir';
    src: local('Avnir'), url(./assets/fonts/AvenirLTStd-Roman.otf) format('opentype');
}

body{
    font-family: "Avnir";
    background-color: #232323;
    color:white;
    padding:0px;
    margin:0px;
}
