.form{
    width: 100%;
    max-width: 450px;
    margin:auto;
    margin-top:100px;
    padding: 20px;
    box-sizing: border-box;
}

.form h3{
    text-align: center;
    font-weight: 200;
    font-size:25px;
}
