button{
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    font-size:20px;
    font-family: Avenir;
    border-radius: 30px;
    color: white;
    outline: none;
    border: 2px solid transparent;
    cursor: pointer;
}

.button-fill{
    background-color: #049256;
}
